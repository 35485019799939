var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"no-close-on-backdrop":"","hide-header-close":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-left",attrs:{"size":"sm","rounded":"","dark":"","loading":_vm.isContinueBtnClick,"color":"primary"},on:{"click":function () {
            _vm.saveImageData();
          }}},[_vm._v(" تایید ")]),_c('b-button',{staticClass:"float-right",attrs:{"size":"sm"},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" لغو ")])],1)]},proxy:true}]),model:{value:(_vm.showCropperModal),callback:function ($$v) {_vm.showCropperModal=$$v},expression:"showCropperModal"}},[_c('div',{staticClass:"text-center d-block"},[_c('b-form',{ref:"form",attrs:{"b-model":"valid"}},[_c('b-row',{staticClass:"justify-center"},[(_vm.imageType == "avatar" && !_vm.imageIsSelected)?_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"sc-590de8b6-0 xHKKd",attrs:{"type":"drag-drop"}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('div',{staticClass:"text-center w-100"},[_c('b-avatar',{attrs:{"color":"white","size":"82"}},[_c('b-icon',{attrs:{"size":"100"}},[_vm._v("mdi-account-circle-outline")])],1)],1),_c('p',{staticClass:"text-center"},[_vm._v("تصویر واضحی از چهره کاربر آپلود کنید.")]),_c('b-row',{attrs:{"justify":"center"}},[_c('b-col',{staticClass:"text-center",attrs:{"lg":"7","md":"7","sm":"12","cols":"12"}},[_c('b-button',{attrs:{"rounded":"","block":"","dark":"","loading":_vm.isContinueBtnClick,"color":"blue-grey"},on:{"click":function () {
                        _vm.$refs.form.validate();
                        _vm.login();
                      }}},[_vm._v(" انتخاب تصویر ")])],1)],1)],1),_c('input',{staticClass:"sc-590de8b6-2 bvrXub",attrs:{"type":"file","tabindex":"-1","accept":".png, .jpg, .jpeg"},on:{"change":function($event){return _vm.uploadImage($event)}}})])]):_vm._e(),(_vm.imageType == "national_code_image")?[(_vm.uploadedNationalCodeImg && !_vm.imageEditMode)?_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-img',{attrs:{"src":this.$axios.defaults.baseURL + _vm.nationalCodeImg.path}}),_c('v-row',{attrs:{"justify":"center"}},[_c('b-col',{staticClass:"text-center",attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_c('v-btn',{staticClass:"mt-10",attrs:{"rounded":"","block":"","dark":"","loading":_vm.isContinueBtnClick,"color":"primary"},on:{"click":function($event){return _vm.resetImage()}}},[_vm._v(" تغییر عکس ")])],1)],1)],1):_vm._e(),(!_vm.imageIsSelected && _vm.imageEditMode)?_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"sc-590de8b6-0 xHKKd",attrs:{"type":"drag-drop"}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('div',{staticClass:"text-center w-100"},[_c('v-avatar',{attrs:{"color":"white","size":"82"}},[_c('v-icon',{attrs:{"size":"82"}},[_vm._v("mdi-camera-outline")])],1)],1),_c('p',{staticClass:"text-center"},[_vm._v("برای انتخاب عکس کلیک کنید")])]),_c('input',{staticClass:"sc-590de8b6-2 bvrXub",attrs:{"type":"file","tabindex":"-1","accept":".png, .jpg, .jpeg"},on:{"change":function($event){return _vm.uploadImage($event)}}})])]):_vm._e(),(_vm.imageIsSelected && _vm.imageEditMode)?_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[(!_vm.imageIsCropped)?_c('div',[_c('cropper',{ref:"cropper",staticClass:"cropper upload-example-cropper",attrs:{"stencil-props":{
                  handlers: {},
                  aspectRatio: 16 / 9,
                  movable: false,
                  scalable: false,
                },"resize-image":{
                  adjustStencil: false,
                },"image-restriction":"stencil","src":_vm.image.src,"canvas":{
                  minHeight: 0,
                  minWidth: 0,
                  maxHeight: 300,
                  maxWidth: 400,
                }},on:{"change":_vm.change}}),_c('b-col',{staticClass:"text-center",attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_c('v-btn',{attrs:{"rounded":"","block":"","dark":"","loading":_vm.isContinueBtnClick,"color":"primary"},on:{"click":function($event){return _vm.crop()}}},[_vm._v(" برش ")])],1)],1):_vm._e(),(_vm.imageIsCropped)?_c('div',{staticClass:"w-100 text-center"},[_c('img',{attrs:{"src":_vm.nationalCardCroppedImage}}),_c('v-row',{attrs:{"justify":"center"}},[_c('b-col',{staticClass:"text-center",attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_c('v-btn',{staticClass:"mt-10",attrs:{"rounded":"","block":"","dark":"","loading":_vm.isContinueBtnClick,"color":"primary"},on:{"click":function($event){return _vm.resetImage()}}},[_vm._v(" تغییر عکس ")])],1)],1)],1):_vm._e()]):_vm._e()]:_vm._e(),(_vm.imageType == "avatar")?[(_vm.imageIsSelected)?_c('b-col',{staticClass:"text-center"},[(!_vm.imageIsCropped)?_c('div',[_c('cropper',{ref:"cropper",staticClass:"cropper upload-example-cropper",attrs:{"stencil-props":{
                  handlers: {},

                  movable: false,
                  scalable: false,
                },"resize-image":{
                  adjustStencil: false,
                },"stencil-component":"circle-stencil","image-restriction":"stencil","src":_vm.image.src,"canvas":{
                  minHeight: 0,
                  minWidth: 0,
                  maxHeight: 300,
                  maxWidth: 400,
                }},on:{"change":_vm.change}}),_c('b-col',{staticClass:"text-center",attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_c('b-button',{attrs:{"rounded":"","block":"","dark":"","loading":_vm.isContinueBtnClick,"color":"primary"},on:{"click":function($event){return _vm.crop()}}},[_vm._v(" برش ")])],1)],1):_vm._e(),(_vm.imageIsCropped)?_c('div',{staticClass:"w-100 text-center"},[_c('b-avatar',{staticStyle:{"border":"1px solid #d6d6d6"},attrs:{"size":"150"}},[_c('img',{attrs:{"src":_vm.avatarCroppedImage}})]),_c('b-row',{attrs:{"justify":"center"}},[_c('b-col',{staticClass:"text-center",attrs:{"lg":"4","md":"6","sm":"12","cols":"12"}},[_c('b-button',{staticClass:"mt-10",attrs:{"rounded":"","block":"","dark":"","loading":_vm.isContinueBtnClick,"color":"primary"},on:{"click":function($event){return _vm.resetImage()}}},[_vm._v(" تغییر عکس ")])],1)],1)],1):_vm._e()]):_vm._e()]:_vm._e()],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }