<template>
  <b-modal no-close-on-backdrop hide-header-close v-model="showCropperModal">
    <div class="text-center d-block">
      <!--Enter new National ID -->
      <!-- ******************************************************************* -->

      <b-form ref="form" b-model="valid">
        <b-row class="justify-center">
          <!--upload  National ID Image-->
          <b-col
            v-if="imageType == `avatar` && !imageIsSelected"
            class="text-center"
            cols="12"
          >
            <div type="drag-drop" class="sc-590de8b6-0 xHKKd">
              <div class="dropzone-custom-content">
                <div class="text-center w-100">
                  <b-avatar color="white" size="82">
                    <b-icon size="100">mdi-account-circle-outline</b-icon>
                  </b-avatar>
                </div>
                <p class="text-center">تصویر واضحی از چهره کاربر آپلود کنید.</p>
                <b-row justify="center">
                  <b-col class="text-center" lg="7" md="7" sm="12" cols="12">
                    <b-button
                      rounded
                      block
                      dark
                      :loading="isContinueBtnClick"
                      color="blue-grey"
                      @click="
                        () => {
                          $refs.form.validate();
                          login();
                        }
                      "
                    >
                      انتخاب تصویر
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <input
                @change="uploadImage($event)"
                type="file"
                tabindex="-1"
                accept=".png, .jpg, .jpeg"
                class="sc-590de8b6-2 bvrXub"
              />
            </div>
          </b-col>

          <template v-if="imageType == `national_code_image`">
            <b-col
              v-if="uploadedNationalCodeImg && !imageEditMode"
              class="text-center"
              cols="12"
            >
              <v-img
                :src="this.$axios.defaults.baseURL + nationalCodeImg.path"
              ></v-img>
              <v-row justify="center">
                <b-col class="text-center" lg="4" md="6" sm="12" cols="12">
                  <v-btn
                    rounded
                    block
                    dark
                    class="mt-10"
                    :loading="isContinueBtnClick"
                    color="primary"
                    @click="resetImage()"
                  >
                    تغییر عکس
                  </v-btn>
                </b-col>
              </v-row>
            </b-col>
            <b-col
              v-if="!imageIsSelected && imageEditMode"
              class="text-center"
              cols="12"
            >
              <div type="drag-drop" class="sc-590de8b6-0 xHKKd">
                <div class="dropzone-custom-content">
                  <div class="text-center w-100">
                    <v-avatar color="white" size="82">
                      <v-icon size="82">mdi-camera-outline</v-icon>
                    </v-avatar>
                  </div>
                  <p class="text-center">برای انتخاب عکس کلیک کنید</p>
                </div>
                <input
                  @change="uploadImage($event)"
                  type="file"
                  tabindex="-1"
                  accept=".png, .jpg, .jpeg"
                  class="sc-590de8b6-2 bvrXub"
                />
              </div>
            </b-col>
            <b-col
              v-if="imageIsSelected && imageEditMode"
              class="text-center"
              cols="12"
            >
              <div v-if="!imageIsCropped">
                <cropper
                  ref="cropper"
                  :stencil-props="{
                    handlers: {},
                    aspectRatio: 16 / 9,
                    movable: false,
                    scalable: false,
                  }"
                  :resize-image="{
                    adjustStencil: false,
                  }"
                  image-restriction="stencil"
                  class="cropper upload-example-cropper"
                  @change="change"
                  :src="image.src"
                  :canvas="{
                    minHeight: 0,
                    minWidth: 0,
                    maxHeight: 300,
                    maxWidth: 400,
                  }"
                ></cropper>
                <b-col class="text-center" lg="4" md="6" sm="12" cols="12">
                  <v-btn
                    rounded
                    block
                    dark
                    :loading="isContinueBtnClick"
                    color="primary"
                    @click="crop()"
                  >
                    برش
                  </v-btn>
                </b-col>
              </div>
              <div v-if="imageIsCropped" class="w-100 text-center">
                <img :src="nationalCardCroppedImage" />
                <v-row justify="center">
                  <b-col class="text-center" lg="4" md="6" sm="12" cols="12">
                    <v-btn
                      rounded
                      block
                      dark
                      class="mt-10"
                      :loading="isContinueBtnClick"
                      color="primary"
                      @click="resetImage()"
                    >
                      تغییر عکس
                    </v-btn>
                  </b-col>
                </v-row>
              </div>
            </b-col>
          </template>
          <template v-if="imageType == `avatar`">
            <b-col v-if="imageIsSelected" class="text-center">
              <div v-if="!imageIsCropped">
                <cropper
                  ref="cropper"
                  :stencil-props="{
                    handlers: {},

                    movable: false,
                    scalable: false,
                  }"
                  :resize-image="{
                    adjustStencil: false,
                  }"
                  stencil-component="circle-stencil"
                  image-restriction="stencil"
                  class="cropper upload-example-cropper"
                  @change="change"
                  :src="image.src"
                  :canvas="{
                    minHeight: 0,
                    minWidth: 0,
                    maxHeight: 300,
                    maxWidth: 400,
                  }"
                ></cropper>
                <b-col class="text-center" lg="4" md="6" sm="12" cols="12">
                  <b-button
                    rounded
                    block
                    dark
                    :loading="isContinueBtnClick"
                    color="primary"
                    @click="crop()"
                  >
                    برش
                  </b-button>
                </b-col>
              </div>
              <div v-if="imageIsCropped" class="w-100 text-center">
                <b-avatar style="border: 1px solid #d6d6d6" size="150">
                  <img :src="avatarCroppedImage" />
                </b-avatar>
                <b-row justify="center">
                  <b-col class="text-center" lg="4" md="6" sm="12" cols="12">
                    <b-button
                      rounded
                      block
                      dark
                      class="mt-10"
                      :loading="isContinueBtnClick"
                      color="primary"
                      @click="resetImage()"
                    >
                      تغییر عکس
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </template>
        </b-row>
      </b-form>
    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          size="sm"
          rounded
          dark
          :loading="isContinueBtnClick"
          color="primary"
          @click="
            () => {
              saveImageData();
            }
          "
          class="float-left"
        >
          تایید
        </b-button>
        <b-button size="sm" class="float-right" @click="closeModal()">
          لغو
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BIcon,
  BIconArrowUp,
  BIconArrowDown,
  BIconPersonFill,
  BForm,
} from "bootstrap-vue";
import { mapMutations, mapState, mapActions } from "vuex";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
export default {
  name: "EditNationalId",
  components: {
    Cropper,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    BIcon,
    BIconArrowUp,
    BIconArrowDown,
    BIconPersonFill,
    BForm,
  },
  props: ["imageType", "showCropperModal", "nationalCodeImg"],
  data() {
    return {
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      croppedImage: null,
      avatarCroppedImage: null,
      nationalCardCroppedImage: null,
      image: {
        src: "",
        type: "image/jpg",
      },
      imageIsSelected: false,
      imageIsCropped: false,
      step: 0,
      valid: true,
      snackName: "",
      showPassword: false,
      showConfirmPassword: false,
      name: "",
      time: 10,
      smsCode: "",
      code: "",
      value: {
        registered: -1,
        level: "",
        id: 0,
        firstName: {
          value: "",
          error: "",
        },
        lastName: {
          value: "",
          error: "",
        },
        mobile: {
          error: "",
          value: "",
        },
        password: {
          value: "",
          error: "",
        },
        confirmPassword: {
          value: "",
          error: "",
        },
      },
      mobileRules: [
        (v) => !!v || "شماره همراه خود را وارد کنید",
        (v) => (v && v.length <= 10) || "شماره موبایل 10 رقم باشد",
      ],
      firstNameRules: [(v) => !!v || "نام خود را وارد کنید"],
      LastNameRules: [(v) => !!v || "نام خانوادگی خود را وارد کنید"],
      passRules: [
        (v) => !!v || "رمز عبور را وارد کنید",
        (v) => (v && v.length >= 8) || "رمز عبور حداقل باید 8 کاراکتر باشد",
      ],
      passConfirmRules: [
        (v) => !!v || "تکرار رمز عبور را وارد کنید",
        (v) =>
          (v && v == this.value.password.value) ||
          "تکرار رمز عبور با رمز عبور مطابقت ندارد",
      ],
      otpRules: [(v) => !!v || "کد پیامک شده را وارد کنید"],
      isContinueBtnClick: false,
      isRegisterBtnClicked: false,
      isResendSmsBtnClick: false,
      isVerifyBtnClick: false,
      isLoginBtnClicked: false,
      isLoginWithOtpBtnClicked: false,
      loginWithOtpCode: false,
      otpErrorMessage: "",
      fileImage: new File([""], "filename"),
      imageIsUploaded: false,
      imageEditMode: true,
    };
  },
  mounted() {
    // Everything is mounted and you can access the dropzone instance
  },
  computed: {
    ...mapState(["editProfileInfoModal"]),
    uploadedNationalCodeImg() {
      if (this.nationalCodeImg.path) {
        this.imageIsUploaded = true;
        return this.nationalCodeImg.path;
      } else {
        this.imageIsUploaded = false;
        return "";
      }
    },
  },
  methods: {
    closeModal() {
      this.resetImage();
      this.$emit(`closeCropperModal`);
    },
    saveImageData() {
      var data = {};
      if (this.imageType == "avatar") {
      }

      if (!this.avatarCroppedImage && this.imageType == "avatar") {
        return;
      }
      if (
        !this.nationalCardCroppedImage &&
        this.imageType == "national_code_image"
      ) {
        return;
      }
      if (this.imageType == "avatar") {
    
        data = {
          imageType: "avatar",
          avatarCroppedImage:this.avatarCroppedImage,
          avatar: this.fileImage,
        };
      } else if (this.imageType == "national_code_image") {
        data = {
          imageType: "national_code_image",
          nationalCardCroppedImage:this.nationalCardCroppedImage,
          national_code_image: this.fileImage,
        };
      }

      this.$emit("updateImage", data);
      this.closeModal()
    },
    resetImage() {
      this.croppedImage = null;
      this.image = {
        src: "",
        type: "image/jpg",
      };
      this.imageIsSelected = false;
      this.imageIsCropped = false;
    },
    crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image, that can be used
      // as src for <img/> to preview result
      if (canvas) {
        canvas.toBlob((blob) => {
          this.fileImage = new File([blob], "my_image.jpeg", {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          });
        }, "image/jpeg");
      }
      if (this.imageType == "avatar") {
        this.avatarCroppedImage = canvas.toDataURL();
      } else if (this.imageType == "national_code_image") {
        this.nationalCardCroppedImage = canvas.toDataURL();
      }

      this.imageIsCropped = true;
    },
    uploadImage(event) {
      /// Reference to the DOM input element
      event;

      const { files } = event.target;
      // Ensure that you have a file before attempting to read it

      if (files && files[0]) {
        this.imageIsSelected = true;
        // if (!this.clickOnNewImage) {
        //   this.$emit("showCropImgaeModal");
        // }
        // this.clickOnNewImage = true;
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
        this.image = {
          src: blob,
          type: files[0].type,
        };
      }
    },
    check() {
      alert("ann");
    },
    change({ coordinates, canvas }) {
      console.log(coordinates, canvas);
    },
    ...mapActions("snackbar", ["showSnack"]),
    checkOtpErrorMessage() {
      this.otpErrorMessage = "";
      if (this.code == "") {
        this.otpErrorMessage = "لطفا کد پیامک شده را وارد کنید";
      } else if (this.code.length < 5) {
        this.otpErrorMessage = "کد وارد شده باید 5 رقم باشد";
      }
    },
    saveDetails() {
      this.showSnack({
        text: "با موفقیت وارد شدید",
        color: "success",
        timeout: 3500,
      });
    },
    removeError() {
      if (this.value.mobile.value.length == 9) {
        console.log("شماره موبایل داره پاک میشه");
        this.value.mobile.error = "";
      }
    },
  },
};
</script>

<style>
.dropzone-custom-content {
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #00b782;
}
.xHKKd {
  position: relative;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #ddd;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0.5rem 0px;
  margin-bottom: 0px;
  overflow: hidden;
  cursor: pointer;
}
.bvrXub {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  opacity: 0;
}
.subtitle {
  color: #314b5f;
}
.upload-example-cropper {
  max-height: 300px;
  min-height: 200px;
}
.vue-advanced-cropper {
  direction: rtl !important;
}
.cropper {
  direction: rtl !important;
}
</style>
