<template>
  <div>

    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
        <b-button
            @click="showFormModal(null)"
            class="px-3 text-capitalize"
            variant="primary"
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" />
          ایجاد کاربر
        </b-button>
      </b-col>
    </b-row>
    <div v-if="users">
      <b-row class="">
        <b-col sm="12" md="3" lg="2">
          <per-page ref="userPerPageComponent" v-model="perPage" />
        </b-col>
        <b-col>
          <search
              @searchBase="searchBase"
              :selectedFilter="selectedFilter"
              ref="usersSearchComponent"
              :submitLoading="searchBasesubmitLoading"
              v-model="searchInput"
              :isSearchResult="isSearchResult"
          />
        </b-col>
      </b-row>
      <b-card body-class="p-0" class="mt-1" >

        <!--        <div class="d-flex flex-wrap justify-content-center items-center"   v-if="loading">
                  <b-spinner

                      v-for="variant in variants"
                      :key="variant"
                      :variant="variant"
                      class="mr-1"
                      type="grow"
                  />
                </div>-->
        <!--        <vue-good-table
                    v-else
                    :columns="columns"
                    :rows="users"
                    :search-options="{
                    enabled: true
                  }"
                    :pagination-options="{
                      enabled: true,
                      perPage:pageLength,
                      mode: 'pages'
              }"
                    :sort-options="{
                    enabled: true,
          }">
                  <template
                      slot="table-row"
                      slot-scope="props"
                  >
                    <div
                        v-if="props.column.field === 'first_name'"
                        class="text-nowrap"
                    >
                      <span class="text-nowrap">{{ props.row.first_name }}</span>
                    </div>
                    <div v-else-if="props.column.field === 'last_name'">
                      {{ props.row.last_name }}
                    </div>
                    <div v-else-if="props.column.field === 'mobile'" style="direction: ltr">
                      {{ props.row.mobile }}
                    </div>
                    <div v-else-if="props.column.field === 'email'">
                      {{ props.row.email }}
                    </div>
                    <div v-else-if="props.column.field === 'created_at'">
                      {{
                        new Date(props.row.created_at).toLocaleString("fa-IR", {
                          timeZone: "Iran",
                        })
                      }}
                    </div>
                    <div v-else>
                      <b-col class="d-flex align-items-center">
                        <b-button
                            @click="showConfirmModal(props.row)"
                            variant="flat-danger"
                            class="font-weight-bolder"
                        >
                          <feather-icon icon="Trash2Icon" size="20" />
                        </b-button>
                        <b-button
                            @click="showFormModal(props.row)"
                            variant="flat-secondary"
                            class="font-weight-bolder"
                        >
                          <feather-icon icon="Edit2Icon" size="20" />
                        </b-button>
                      </b-col>
                    </div>
                  </template>

                </vue-good-table>-->


        <table-head
            ref="usersTableHeadComponent"
            :items="tableHeadings"
            @sort="sort"
        />
        <b-row
            class="py-1 mx-1 border-bottom"
            v-for="user in users"
            :key="user.id"
        >
          <b-col class="d-flex align-items-center pl-0">
            {{ user.first_name }}
          </b-col>
          <b-col class="d-flex align-items-center">
            {{ user.last_name }}
          </b-col>
          <b-col class="d-flex align-items-center">
            {{ user.mobile.replace("+98", "0") }}
          </b-col>
          <b-col class="d-flex align-items-center">
            {{ user.email }}
          </b-col>
          <b-col
              class="d-flex align-items-center flex-row-reverse"
              style="direction: ltr"
          >
            {{
              new Date(user.created_at).toLocaleString("fa-IR", {
                timeZone: "Iran",
              })
            }}
          </b-col>
          <b-col class="d-flex align-items-center">
            <b-button
                @click="showConfirmModal(user)"
                variant="flat-danger"
                class="font-weight-bolder"
            >
              <feather-icon icon="Trash2Icon" size="20" />
            </b-button>
            <b-button
                @click="showFormModal(user)"
                variant="flat-secondary"
                class="font-weight-bolder"
            >
              <feather-icon icon="Edit2Icon" size="20" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <pagination
          ref="usersPaginationComponent"
          v-model="page"
          :paginationData="paginationData"
      />
    </div>
    <b-sidebar
        width="500px"
        v-model="formModal"
        right
        backdrop
        shadow
        bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
                style="background-color: rgba(75, 75, 75, 0.12)"
                @click="formModal = false"
                variant="flat-dark"
                class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? $t("edit user") : $t("add user") }}
            </h3>
          </div>
        </div>
      </template>
      <b-container class="">
        <b-form-group class="mt-1">
          <label for="national_code_image" class="text-capitalize">
            عکس پروفایل :
          </label>
          <br />

          <div class="float-left w-50 text-center">
            <b-button
                block
                class="mt-1"
                @click="showCropperModal('avatar', true, null)"
            >انتخاب عکس</b-button
            >

            <b-button
                :disabled="!avatar"
                block
                @click="
                () => {
                  (avatar = null), (avatarCroppedImage = null);
                }
              "
            >حذف عکس</b-button
            >
          </div>
          <div class="float-right w-50 text-center">
            <b-avatar :src="avatarCroppedImage" size="6rem"></b-avatar>
            <!-- <img  width="90%" :src="avatarCroppedImage"/> -->

            <!-- <p v-if="avatar">{{ avatar.name }}</p> -->
          </div>
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="user_mobile" class="text-capitalize"
          >{{ $t("mobile") }}:<span class="text-danger">*</span></label
          >
          <b-form-input
              dir="ltr"
              id="user_mobile"
              v-model="userInput.mobile"
              type="text"
              placeholder="09156662233"
              :required="!editMode"
          />
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="user_first_name" class="text-capitalize"
          >{{ $t("first name") }}:<span class="text-danger">*</span></label
          >
          <b-form-input
              id="user_first_name"
              v-model="userInput.first_name"
              type="text"
          />
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="user_last_name" class="text-capitalize"
          >{{ $t("last name") }}:<span class="text-danger">*</span></label
          >
          <b-form-input
              id="user_last_name"
              v-model="userInput.last_name"
              type="text"
          />
        </b-form-group>
        <b-form-group class="mt-1" v-slot="{ ariaDescribedby }">
          <label for="card_number" class="text-capitalize">
            {{ $t("gender") }} :
          </label>
          <b-form-radio
              v-model="userInput.gender"
              :aria-describedby="ariaDescribedby"
              value="male"
          >مرد</b-form-radio
          >
          <b-form-radio
              v-model="userInput.gender"
              :aria-describedby="ariaDescribedby"
              value="female"
          >زن</b-form-radio
          >
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="email" class="text-capitalize">{{ $t("email") }}:</label>
          <b-form-input id="email" v-model="userInput.email" type="text" />
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="user_pass" class="text-capitalize"
          >{{ $t("password") }}:<span class="text-danger">*</span></label
          >
          <b-form-input
              id="user_pass"
              v-model="userInput.password"
              type="password"
          />
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="user_confirm_pass" class="text-capitalize"
          >{{ $t("password_confirmation") }}:<span class="text-danger"
          >*</span
          ></label
          >
          <b-form-input
              id="confirm_pass"
              v-model="userInput.password_confirmation"
              type="password"
          />
        </b-form-group>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
              class="text-capitalize mr-2"
              @click="formModal = false"
              variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm()">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
    <image-cropper
        :nationalCodeImg="``"
        :imageType="modalType"
        :showCropperModal="showModal"
        @closeCropperModal="closeCropperModal"
        @updateImage="updateImage"
    ></image-cropper>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BFormRadio,
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";
import ImageCropper from "@/components/ImageCropper.vue";

export default {
  components: {
    VueGoodTable,
    ImageCropper,
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    BFormRadio,
  },
  data() {
    return {
      variants: ['primary', 'secondary', 'danger', 'warning', 'success', 'info', 'light', 'dark'],

      loading: false,

      pageLength: 20,
      columns: [
        {
          label:  'نام',
          field: 'first_name',
        },
        {
          label: 'نام خانوادگی',
          field: 'last_name',
        },
        {
          label: 'موبایل',
          field: 'mobile',
        },
        {
          label: 'ایمیل',
          field: 'email',
        },
        {
          label: 'تاریخ',
          field: 'created_at',
        },
        {
          label: 'عملیات',
          field: 'action',
        },
      ],
      rows: [],


      avatarCroppedImage: null,
      avatar: null,
      nationalCardCroppedImage: null,
      showModal: false,
      modalType: "",
      perPage: 10,
      page: 1,
      users: null,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,
      tableHeadings: [
        {
          title: "نام",
          slug: "first_name",
          sortable: true,
        },
        {
          title: "نام خانوادگی",
          slug: "last_name",
          sortable: true,
        },
        {
          title: "موبایل",
          slug: "mobile",
          sortable: true,
        },
        {
          title: "ایمیل",
          slug: "mobile",
          sortable: true,
        },
        {
          title: "تاریخ ایجاد",
          slug: "created_at",
          sortable: true,
        },
        {
          title: "actions",
          sortable: false,
        },
      ],

      OpenIndicator: {
        render: (createElement) =>
            createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      companies: [],
      companiesCancelToken: undefined,
      userInput: {
        first_name: "",
        password: "",
        password_confirmation: "",
        last_name: "",
        mobile: "",
        email: "",
        gender: "",
      },
      editMode: false,
      formModal: false,
      submitLoading: false,
      selectedFilter: [],
      isSearchResult: false,
      searchBasesubmitLoading: false,
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    // this.getUrlParams();
    this.getUsers();
    // this.getCompanies();
  },
  watch: {
    /*   searchInput(value) {
         this.page = 1;
         this.getUsers();
       },*/
    searchInput(value) {
      this.page = 1; // Reset the page to the beginning
      if (value.trim().length) { // Ensure there is actual input to search for
        this.debouncedGetReservations();
      }
    },
    perPage(value) {
      this.page = 1;
      this.getUsers();
    },
    page(value) {
      this.getUsers();
    },
  },
  created() {
    this.debouncedGetReservations = debounce(this.getUsers(), 500);
  },
  methods: {
    getUrlParams() {
      if ("filter[name]" in this.$route.query) {
        this.searchInput = this.$route.query[`filter[name]`];
        this.selectedFilter.push("name");
      }
      if ("filter[email]" in this.$route.query) {
        this.searchInput = this.$route.query[`filter[email]`];
        this.selectedFilter.push("email");
      }
      if ("filter[mobile]" in this.$route.query) {
        this.searchInput = this.$route.query[`filter[mobile]`];
        this.selectedFilter.push("mobile");
      }
      if ("per_page" in this.$route.query) {
        this.perPage = Number(this.$route.query[`per_page`]);
        this.$refs.userPerPageComponent.perPage = this.perPage;
      }
      if ("page" in this.$route.query) {
        this.page = this.$route.query[`page`];
      }

    },


    /*    getUrlParams() {
          if ("filter[name]" in this.$route.query) {
            this.searchInput = this.$route.query[`filter[name]`];
            this.selectedFilter.push("name");
          }
          if ("filter[email]" in this.$route.query) {
            this.searchInput = this.$route.query[`filter[email]`];
            this.selectedFilter.push("email");
          }
          if ("filter[mobile]" in this.$route.query) {
            this.searchInput = this.$route.query[`filter[mobile]`];
            this.selectedFilter.push("mobile");
          }
          if ("per_page" in this.$route.query) {
            this.perPage = Number(this.$route.query[`per_page`]);
            console.log(
                "this.userPerPageComponent",
                this.$refs.userPerPageComponent
            );
            this.$refs.userPerPageComponent.perPage = this.perPage;
          }
          if ("page" in this.$route.query) {
            this.page = this.$route.query[`page`];
          }
          console.log("this.selectedFilter", this.selectedFilter);
        },*/
    searchBase(data) {
      this.selectedFilter = data.selectedFilter;
      this.searchInput = data.searchInput;
      this.isSearchResult = this.searchInput !== "" && data.type !== "delete";
      this.searchBasesubmitLoading = true;
      this.getUsers();
    },
    /*  searchBase(data) {
        console.log("data", data);
        this.selectedFilter = [];
        if (data.selectedFilter.length > 0) {
          data.selectedFilter.forEach((item) => {
            if (item == "name") {
              this.searchInput = data.searchInput;
            } else if (item == "mobile") {
              this.searchInput = data.searchInput;
            } else if (item == "email") {
              this.searchInput = data.searchInput;
            }
          });
          this.selectedFilter = data.selectedFilter;
        }
        this.searchBasesubmitLoading = true;
        if (this.searchInput) {
          this.isSearchResult = true;
        }
        if (data.type == "delete") {
          this.isSearchResult = false;
        }
        this.getUsers();
      },*/
    showCropperModal(modalType, showModal, nationalCodeImg) {
      this.modalType = modalType;
      this.showModal = showModal;
      // this.nationalCodeImg = { path: nationalCodeImg };
    },
    closeCropperModal() {
      this.modalType = "";
      this.showModal = false;
    },
    updateImage(data) {
      if (data.imageType == "avatar") {
        this.avatarCroppedImage = data.avatarCroppedImage;
        this.avatar = data.avatar;
        console.log("avatar", this.avatar);
      } else if (data.imageType == "national_code_image") {
        this.nationalCardCroppedImage = data.nationalCardCroppedImage;
        this.national_code_image = data.national_code_image;
        console.log("national_code_image", this.national_code_image);
      }
    },
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getUsers();
    },

    async getUsers() {
      this.loading = true
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();



      let data = {
        // search: this.searchInput,
        "filter[name]": this.selectedFilter.includes("name")
            ? this.searchInput
            : "",
        "filter[email]": this.selectedFilter.includes("email")
            ? this.searchInput
            : "",
        "filter[mobile]": this.selectedFilter.includes("mobile")
            ? this.searchInput
            : "",
        per_page: this.perPage,
        page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      /*      let data = {
              search: this.searchInput,
              per_page: this.perPage,
              page: this.page,
              sort_by: this.sortBy,
              sort_dir: this.sortDir,
            };*/

      const requestData = {
        method: "get",
        //  url: "/admin/users/get_all_users",
        url: "/admin/users/user",
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data;
            this.users = data.data;
            this.paginationData = data.meta;
            this.searchBasesubmitLoading = false;
          })
          .catch((err) => {
            console.log(err);
            this.searchBasesubmitLoading = false;
          })
          .finally(() => (this.loading = false));
    },
    showConfirmModal(user) {
      this.$bvModal
          .msgBoxConfirm(
              this.$t("Please confirm that you want to delete user") +
              `: ${user.first_name} ${user.last_name}.`,
              {
                title: this.$t("Please Confirm"),
                size: "sm",
                okVariant: "primary",
                okTitle: this.$t("Yes"),
                cancelTitle: this.$t("No"),
                cancelVariant: "outline-secondary",
                hideHeaderClose: false,
                centered: true,
              }
          )
          .then(async (confirmed) => {
            if (confirmed) {
              let userIndex = 0;
              this.users = await this.users.filter((userItem, index) => {
                if (userItem.id == user.id) {
                  userIndex = index;
                }
                return userItem.id !== user.id;
              });

              const requestData = {
                method: "delete",
                url: `/admin/users/delete/${user.id}`,
              };
              this.$http(requestData)
                  .then((response) => {
                    this.$bvToast.toast(
                        `کاربر: ${user.first_name} ${user.last_name} با موفقیت حذف شد.`,
                        {
                          title: this.$t("Done"),
                          variant: "success",
                          solid: true,
                        }
                    );
                  })
                  .catch((err) => {
                    let message = this.$t("Unable to delete!");
                    if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    )
                      message = err.response.data.message;
                    this.$bvToast.toast(message, {
                      title: this.$t("Error"),
                      variant: "danger",
                      solid: true,
                    });
                    this.users.splice(userIndex, 0, user);
                  });
            }
          });
    },
    showFormModal(user) {
      if (user) {
        this.userInput = {
          id: user.id,
          first_name: user.first_name,
          mobile: user.mobile.replace("+98", "0"),
          last_name: user.last_name,
          email: user.email,
          gender: user.gender,
        };
        this.avatarCroppedImage =
            this.$http.defaults.fileUrl + user.avatar.path;
        this.editMode = true;
      } else {
        this.userInput = {
          id: "",
          first_name: "",
          mobile: "",
          last_name: "",
          email: "",
          gender: "male",
        };
        this.editMode = false;
        this.avatarCroppedImage = null;
      }
      this.formModal = true;
    },
    submitForm() {
      if (this.submitLoading) return;
      let errorMessage = null;
      if (!errorMessage && !this.userInput.mobile) {
        errorMessage = "لطفا موبایل کاربر را وارد کنید";
      }
      if (!errorMessage && !this.userInput.first_name) {
        errorMessage = "لطفا نام کاربر را وارد کنید";
      }
      if (!errorMessage && this.userInput.first_name.length < 2) {
        errorMessage = "نام کاربر نمی تواند کمتر از دو کاراکتر باشد";
      }
      if (!errorMessage && !this.userInput.last_name) {
        errorMessage = "لطفا نام خانوادگی کاربر را وارد کنید";
      }

      if (!errorMessage && this.userInput.last_name.length < 2) {
        errorMessage = "نام  خانوادگی کاربر نمی تواند کمتر از دو کاراکتر باشد";
      }
      if (!errorMessage && !this.userInput.mobile) {
        errorMessage = "لطفا موبایل کاربر را وارد کنید";
      }
      if (!errorMessage && !this.validateMobile()) {
        errorMessage = "لطفا شماره موبایل صحیح وارد کنید";
      }

      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return;
      }
      var mobile = parseInt(this.userInput.mobile, 10);
      mobile = `+98${mobile}`;
      this.submitLoading = true;
      let formData = new FormData();
      if (this.avatar) {
        formData.append("avatar", this.avatar);
      }
      if (this.userInput.first_name) {
        formData.append("first_name", this.userInput.first_name);
      }
      if (this.userInput.last_name) {
        formData.append("last_name", this.userInput.last_name);
      }
      if (this.userInput.mobile) {
        formData.append("mobile", mobile);
      }
      if (this.userInput.email) {
        formData.append("email", this.userInput.email);
      }
      if (this.userInput.gender) {
        formData.append("gender", this.userInput.gender);
      }
      if (this.userInput.password) {
        formData.append("password", this.userInput.password);
      }
      if (this.userInput.password_confirmation) {
        formData.append(
            "password_confirmation",
            this.userInput.password_confirmation
        );
      }
      const requestData = {
        method: "post",
        url: this.editMode
            ? `/admin/users/update/${this.userInput.id}`
            : `/admin/users/create/user`,
        data: formData,
      };

      this.$http(requestData)
          .then((response) => {
            let message = "کاربر با موفقیت اضافه شد";
            if (this.editMode) message = "کاربر با موفقیت ویرایش شد";
            this.$bvToast.toast(message, {
              title: this.$t("Done"),
              variant: "success",
              solid: true,
            });
            if (!this.editMode && this.users && this.users.length > 0) {
              this.$refs.usersPaginationComponent.clear();
              this.$refs.usersSearchComponent.clear();
              this.$refs.usersTableHeadComponent.clear();
              this.page = 1;
              this.searchInput = "";
              this.sortBy = "";
              this.sortDir = "";
            }

            this.submitLoading = false;
            this.formModal = false;
            this.getUsers();
          })
          .catch((err) => {
            let message = "خطا در ایجاد کاربر";
            if (this.editMode) message = "خطا در ویرایش کاربر";
            if (err.response && err.response.data && err.response.data.message)
              message = err.response.data.message;

            this.$bvToast.toast(message, {
              title: this.$t("Error"),
              variant: "danger",
            });
            this.submitLoading = false;
            console.log(err);
          });
    },
    getCompanies(search, loading) {
      if (typeof this.companiesCancelToken != typeof undefined) {
        this.companiesCancelToken.cancel(
            "Operation canceled due to new request."
        );
      }
      //Save the cancel token for the current request
      this.companiesCancelToken = this.$http.CancelToken.source();

      let data = {
        search: search,
        per_page: 10,
        page: 1,
      };

      const requestData = {
        method: "get",
        url: "companies",
        params: data,
        cancelToken: this.companiesCancelToken.token,
      };
      this.$http(requestData)
          .then((response) => {
            let data = response.data.data;
            this.companies = data.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    validateMobile() {
      return String(this.userInput.mobile)
          .toLowerCase()
          .match(/09([0-9]{9})/);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
